import { createSlice } from "@reduxjs/toolkit";

export const PromosInitialState = {
    promos: [],
    selectedPromos: [],
    NiveauPromos:0,
    SizeSelected:''
};



export const promosSlice = createSlice({
    name: "promos",
    initialState: PromosInitialState,
    reducers: {
        setPromos: (state, action) => {
            state.promos = action.payload.promos
        },
        setNiveauPromos: (state, action) => {
            state.NiveauPromos = action.payload.NiveauPromos
        },
        setSizeSelected: (state, action) => {
            state.SizeSelected = action.payload.SizeSelected
        },
        setSelectedPromos: (state, action) => {
            state.selectedPromos.push(action.payload.selectedPromo)
        },
        resetPromo: (state) => {
            state.selectedPromos = []
        },
        deletePromos: (state, action) => {
            state.selectedPromos = action.payload.selectedPromos
        },
        addManyPromos: (state, action) => {
            state.selectedPromos = action.payload.selectedPromos
        },
        setPromoToOldPromos: (state, action) => {
            for (let i = 0; i < action.payload.selectedPromos.length; i++) {
                state.selectedPromos.push(action.payload.selectedPromos[i])
            }
        },

    },
});

export const { setPromos, setSelectedPromos, resetPromo, deletePromos, setPromoToOldPromos,setNiveauPromos, setSizeSelected,} = promosSlice.actions;
