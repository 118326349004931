import { BaseURI } from "../..";
import { Executor } from "../../Executor";


export const GetAdressDeliveryByUserId = (userId) => {
  return Executor({
      method: 'get',
      url: BaseURI + `/client/GetAdressDeliveryByUserId/${userId}`,
      isSilent: true,
      successFun: (data) => {
      },
      withErrorToast: false,
      withSuccessToast: false,
  });
}

export const AddDeliveryAddress = async (userId, Nameaddress, Type, Lat, Lon, Note) => {

  const encryptedNameAddress = encodeURIComponent(Nameaddress);

  const data = {
    Nameaddress: encryptedNameAddress,
    Type: Type,
    Lat: Lat,
    Lon:Lon,
    Note: Note,
  };

  try {
    const response = await Executor({
      method: 'post',
      data,
      url: `${BaseURI}/client/AdressDelivery/${userId}`,
      isSilent: false,
      successFun: (response) => {
        console.log("Success:", response);
        return response; 
      },
      withErrorToast: false,
      withSuccessToast: false,
    });

    return response; 
  } catch (error) {
    console.error('Service Error:', error);
    throw error; 
  }
};
export const DeleteDeliveryAddress = async (userId, NameAddress, Type) => {
  console.log("type",Type);

  try {
    const response = await Executor({
      method: 'delete',
      url: `${BaseURI}/client/DeleteAdressDelivery/${userId}/${encodeURIComponent(NameAddress)}/${Type}`,
      isSilent: false,
      successFun: (response) => {
        console.log("Success:", response);
        return response; 
      },
      withErrorToast: false,
      withSuccessToast: false,
    });

    return response; 
  } catch (error) {
    console.error('Service Error:', error);
    throw error; 
  }
};


