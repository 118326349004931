import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import InformationStore from "../../../../components/Menu/InformationStore";
import {
    setApply,
    setEditOrder,
    setFrais,
    setMenu,
    setMode,
    setModeName,
    setModeSelectedId,
    setOpenModal,
    setProduct,
    setRestaurantSelected,
    setRestaurantSlug,
    setTaux,
} from "../../../../shared/slice/restaurant/RestaurantSlice";
import {
    getMenuByStore,
    getMode,
    getModeConsomation,
    getProductByStoreByMode,
    getProductByStoreByModeWithout,
    getStoreById,
    getStoreID,
} from "../../../../shared/slice/restaurant/RestaurantService";
import Menu from "../../../../components/Menu/Menu";
import MyNavBar from "../../../../components/Navbar/MyNavBar";
import StoreNavbar from "../../../../components/StoreNavbar/StoreNavbar";
import { lighten } from "polished";
import ModalModes from "../../../../components/Modals/ModalModes";
import ModalStores from "../../../../components/Modals/ModalStores";
import { setModeDelivery, setModesModal } from "../../../../shared/slice/ModalMode/ModalMode";
import { BaseURI, ImageURI, store } from "../../../../shared";
// import { setScroll } from "../../../../shared/slice/scroll/ScrollSlice";
// import Cookies from 'js-cookie';
// import { setOrder } from "../../../../shared/slice/order/OrderSlice";
// import { disconnects, setLoggedInGuest, setToken } from "../../../../shared/slice/auth/AuthSlice";
// import { setModelGuest } from "../../../../shared/slice/ModalLogin/ModalLoginSlice";
import { setRootLoading } from "../../../../shared/slice/rootSlice";
import { socketRoald } from "../../../../App";
import { setOrder } from "../../../../shared/slice/order/OrderSlice";
export default function SelectStore() {
    const modesModal = useSelector((state) => state.modalMode.modesModal);

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const restaurantSelected = useSelector(
        (state) => state.restaurant.restaurantSelected
    );
    const modeSelectedId = useSelector(
        (state) => state.restaurant.modeSelectedId
    );
    const orders = useSelector((state) => state.order.order);

    setModesModal(true)
    
    const modes = useSelector((state) => state.restaurant.mode);
    useEffect(() => {
        const FetchUpdatedMenu = async () => {
            try {
              
                const res2 = await getMenuByStore(restaurantSelected?._id);
                dispatch(setMenu({ menu: res2 }));
                
            } catch (error) {
                console.error("Error fetching updated menu:", error);
            }
        };
    
        const FetchUpdatedProduct = async () => {
            try {
     
                const res33 = await getProductByStoreByModeWithout(restaurantSelected?._id, modeSelectedId);
           
                let ProductForMessage =""
                for (let i = 0; i <orders.length; i++) {
                    const productEqOption = res33.find((p) => p._id.toString() === orders[i].product.id);
                    if(productEqOption.availability===false) {
                        if (i===0){ ProductForMessage=productEqOption.name}
                        else{ ProductForMessage=ProductForMessage+", "+productEqOption.name}
                    }
                }
                if(ProductForMessage!==''){alert(ProductForMessage)
                    dispatch(setOrder({ order: [] })); 
                }
                store.dispatch(setProduct({ product: res33 }));
               
            } catch (error) {
                console.error("Error fetching updated product:", error);
            }
        };
    
        const fetchUpdatedBoth = async () => {
          
            
            await FetchUpdatedMenu();
            await FetchUpdatedProduct();
        
        };
    
        socketRoald.on('UpdatedMenu' + restaurantSelected?._id, fetchUpdatedBoth);
        socketRoald.on('UpdatedProduct' + restaurantSelected?._id, fetchUpdatedBoth);
        socketRoald.on('UpdatedBoth' + restaurantSelected?._id, fetchUpdatedBoth);
    
        return () => {
            socketRoald.off('UpdatedMenu' + restaurantSelected?._id, fetchUpdatedBoth);
            socketRoald.off('UpdatedProduct' + restaurantSelected?._id, fetchUpdatedBoth);
            socketRoald.off('UpdatedBoth' + restaurantSelected?._id, fetchUpdatedBoth);
        };
    }, [socketRoald, restaurantSelected?._id,modeSelectedId]); 
    
    

    useEffect(() => {
        console.log("wessmodesModal",modesModal)
    }, [modesModal]);

    useEffect(() => {
        const getmode = async () => {
            if (modeSelectedId) {
                const mode = await getMode(modeSelectedId);
                if (mode.mode.applyTaux) {
                    dispatch(setTaux({ taux: mode.mode.taux }));
                } else {
                    dispatch(setFrais({ frais: mode.mode.frais }));
                }
                dispatch(setApply({ apply: mode.mode.applicationType }))
                dispatch(setModeName({ modeName: mode.mode.name }))
            }
        };
        getmode();
    }, [modeSelectedId, restaurantSelected?._id]);
    // const scroll = useSelector((state) => state.scroll.scroll);

 
    const preloadImages = (filteredCategories) => {
        filteredCategories.forEach((category) => {
          const img = new Image();
          img.src = `${ImageURI}/combined-uploads/${category.image}`;
        });
      };
    
    useEffect(() => {
    
        const fetchedStoresById = async () => {
            

            store.dispatch(setRootLoading(false));
           
      
            //document.documentElement.style.overflow = 'hidden';
            // console.log("--------------------------",ModelGuest)
            // store.dispatch(setToken({}));
            // store.dispatch(setLoggedInGuest({ user: {} }));
            store.dispatch(setModeDelivery({ modeDelivery: false }));
            store.dispatch(setOpenModal({
                product: undefined,
                open: false,
              }));
            
            store.dispatch(setEditOrder(
                {
                   open: false,
                   objectOptions:[],
                   checkedIndices: [],
                   checkedOptions: [],
                   counter:{},
                   orderQuantity:0,
                   note:undefined,
                   size:""
               }
               ));
            store.dispatch(setModesModal({ modesModal: true }));
           const StroreeId = await getStoreID(id) 
    
            getModeConsomation(StroreeId)
            .then((res4) => {
                dispatch(setMode({ mode: res4.consumationModes }));
                // console.log(res4.consumationModes);
            })
            .catch((err) => {
                console.log("Page not found");
             navigate(`/page404`);
            });


            getStoreById(StroreeId)
                .then(async (res) => {
                    
                    dispatch(setRestaurantSelected({ restaurantSelected: res }));
                    // dispatch(setModeSelectedId({ modeSelectedId: res.defaultMode }))
                    
                    dispatch(setModeSelectedId({ modeSelectedId: modeSelectedId }));
                    document.documentElement.style.setProperty(
                        "--primaryColor",
                        res.primairecolor
                    );
                    document.documentElement.style.setProperty(
                        "--primaryColorLight",
                        lighten("0.3", res.primairecolor)
                    );

                    getMenuByStore(StroreeId)
                        .then((res2) => {
                            dispatch(setMenu({ menu: res2 }));
                            preloadImages( res2?.categorys);

                        })
                        .catch((err) => {
                            console.log("Page not found");
                         navigate(`/page404`);
                        });
                       

                        
                        // const storedOrders = localStorage.getItem('OrderStoreWithID'+id);
                        // const orders = JSON.parse(storedOrders); // Convert to JavaScript object

                        // if (orders) {
                        //     dispatch(setOrder({ order: orders }));
                        // }




                })
                .catch((err) => {
                    console.log("Page not found");
                     navigate(`/page404`);
                });
        };

        fetchedStoresById();
    }, []);

    return (
      <div>  {restaurantSelected && modes ? (
            <div>
                <div style={{  
                    position: "sticky",
                    top: "0",
                    zIndex: "1005",
                    backgroundColor: "white"
                }}>
                    <MyNavBar />
                    <ModalModes />
                    <StoreNavbar />
                    <ModalStores />
                </div>
                <Menu />
            </div>
        ) : (
            <div>
                {modes && 
                <ModalModes />}
            </div>
        )}
        </div>
    );
}
