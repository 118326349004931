import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import Form from "react-bootstrap/Form";
import { useSelector } from 'react-redux';
import { store } from '../../shared';
import { setModalPrincipal, setModelGuest } from '../../shared/slice/ModalLogin/ModalLoginSlice';
import { getGuest, loginGuest, registerGuest, sendSMS, } from '../../shared/slice/auth/AuthService';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from 'styled-components';
import { eatorder } from '../../assets/images/exports';
import { setScroll } from '../../shared/slice/scroll/ScrollSlice';
import { useTranslation } from 'react-i18next';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { setLoggedInGuest, setToken, setmodalCheckout } from '../../shared/slice/auth/AuthSlice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { setDisable } from '../../shared/slice/restaurant/RestaurantSlice';
import AddressemodeGuest from '../AddressInput/AddressemodeGuest';
export default function ModelGuest() {
  const { t } = useTranslation();

  const ModelGuest = useSelector((state) => state.modalLogin.ModelGuest);
  const [phonevalide, setphonevalide] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("")
  const [showDiv, setShowDiv] = useState(false);


  const stores = useSelector((state) => state.restaurant.restaurantSelected)

 
  const [location, setLocation] = useState(null);
  const modeDelivery = useSelector((state) => state.modalMode.modeDelivery);
  const restaurantSelected = useSelector(
    (state) => state.restaurant.restaurantSelected
  );
  const address = useSelector((state) => state.ModalNotification.Address);

  const [errorMessage, setErrorMessage] = useState('');
  

  useEffect(() => {
    if (modeDelivery) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [modeDelivery]);

  // Calculate the distance between two sets of latitude and longitude coordinates
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth radius in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance;
  };
  const onSelectLocation = (selectedLocation) => {
    if (selectedLocation) {

      setLocation(selectedLocation);
    }
  };
  const calculateDistanceFromInput = (location) => {
    if (location) {
      const distance = calculateDistance(
        location.value.lat,
        location.value.lon,
        restaurantSelected.latitude,
        restaurantSelected.longitude
      ).toFixed(2);
      if (distance > restaurantSelected.rangeValue) {
        store.dispatch(setDisable({ disable: true }));
        
      } else {
        store.dispatch(setDisable({ disable: false }));
        
      }
    } else {
      store.dispatch(setDisable({ disable: true }));
    }
  };
  useEffect(() => {
    calculateDistanceFromInput(location);
  }, [location]);


  useEffect(() => {
    if (ModelGuest) {
      store.dispatch(setScroll({ scroll: 1}));
    }
  }, [ModelGuest]);

  const handleCloseSignup = () => {
    store.dispatch(setModelGuest({ ModelGuest: false }));
    store.dispatch(setScroll({ scroll: 0 }));
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleCodeChange = (e) => {

    setVerificationCode(e.target.value)
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };


  const handlePhoneNumberChange = (value, event) => {
    setPhoneNumber(value);
  };
  const handleEmailChange = (e    ) => {
    setEmail(e.target.value);
  };

  const handleSignup = async () => {
    const newUser = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
    };

    try {
      // Send SMS verification code
      const smsResponse = await sendSMS(newUser); // Pass newUser to sendSMS function

      if (smsResponse) {
        store.dispatch(setScroll({ scroll: 0 }));

        setShowDiv(true);     
      } else {
        console.error("Error sending verification email");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const OpenCheckOut = useSelector((state) => state.modalStores.OpenCheckOut);
  const checkout = async () => {
    
 
    if (OpenCheckOut){
    store.dispatch(setmodalCheckout({ modalCheckout: true }))
    }
  };
  const handleGuest = async () => {
    try {
      const guest = await getGuest(phoneNumber, firstName, lastName);
      if (!guest) {
        console.error("Guest not found");
        return;
      }
      if (guest.verificationCode === verificationCode) {
        
        // setShowDivs(true);


      } else {
        console.error("Invalid verification code");
        toast.dismiss();
        toast.error(
          `${t('validate your code')}  `,
          {
            position: "top-right",
            autoClose: 1500,
            pauseOnHover: false,
            transition: Slide,
          }
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleLogin = async () => {
    try {
      const guestData = {
        phoneNumber,
        firstName,
        lastName,
        verificationCode,
      };
  
      if (stores.guestmode[0].other.email) {
        guestData.email = email;
      }
      
      if (stores.guestmode[0].other.address) {
        guestData.address = address;
      }
  
      const response = await loginGuest(guestData);
  
      if (response) {
        const { token, guest } = response; // Destructure token and guest from response
        store.dispatch(setToken({ token }));
        store.dispatch(setLoggedInGuest({ user: guest }));
        checkout()
        console.log("Login success. User:", guest); // Log the user object
      } else {
        console.log("Login failed. Response:", response);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  
  const isValidPhoneNumber = (phoneNumber) => {
    // If phone number is null, undefined, or empty
    if (!phoneNumber) {
      return false;
    }

    // Remove any spaces, dashes, or parentheses
    const cleanNumber = phoneNumber.replace(/[\s()-]/g, '');
    
    // Must start with + and have country code
    if (!cleanNumber.startsWith('+')) {
      return false;
    }

    // Country-specific patterns
    const patterns = {
      FR: /^\+33[1-9][0-9]{8}$/, // French numbers: +33 followed by 9 digits
      US: /^\+1[2-9][0-9]{9}$/,  // US numbers: +1 followed by 10 digits
    };

    // Get country code from phone number
    const countryCode = cleanNumber.slice(0, 3); // Gets +33, +1, etc.
    
    // Check specific country patterns first
    if (countryCode === '+33') {
      return patterns.FR.test(cleanNumber);
    } else if (countryCode === '+1') {
      return patterns.US.test(cleanNumber);
    }
    
    // For other countries:
    // 1. Must start with +
    // 2. Must have at least 11 digits (including country code)
    // 3. Must not exceed 15 digits
    // 4. Must only contain numbers after the +
    return /^\+[1-9]\d{9,14}$/.test(cleanNumber);
  };

  const handleRegister = async () => {
if(phoneNumber!== undefined) {
  
const testphone = isValidPhoneNumber(phoneNumber);
if(testphone===false){setphonevalide(false);
  return;}
}

setphonevalide(true)

      if (!firstName || !lastName) {
      setErrorMessage("First name and last name are required."); 
    return; // Exit the function if validation fails
  }
 
  setErrorMessage('');
    try {
      let guestData = {
        firstName,
        lastName,
        email,
        address,
        phoneNumber
      };
  
 
      const response = await registerGuest(guestData);
      
      if (response) {
        const { token, guest } = response; // Ensure the correct property name is used
        store.dispatch(setToken({ token }));
        store.dispatch(setLoggedInGuest({ user: guest })); // Pass the user object correctly
        checkout()
        console.log("Login success. User:", guest); // Log the user object
      } else {
        console.log("Login failed. Response:", response);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  
  
  
  const openModal = () => {
    store.dispatch(setScroll({ scroll: 0 }));
    store.dispatch(setModelGuest(false));
    store.dispatch(setModalPrincipal({ modalPrincipal: true }));
  };

  return (
    <StyledModalSignup
      isOpen={ModelGuest}
      onRequestClose={handleCloseSignup}
    >
      <div style={{ display: "flex" }}>
        <button onClick={() => openModal()}>
          <KeyboardBackspaceIcon />
        </button>
        <img
          alt='Eatorder'
          src={eatorder}
          style={{
            width: "150px",
            height: "50px",
            objectFit: "contain",
            marginLeft: "29%"
          }}
        ></img>
      </div>
      <h3 className="mb-5 mt-5">{t("Guest Mode")}</h3>
      <div style={{ display: "flex", width: '90%', marginLeft: '5%' }}>

        {stores.guestmode[0].other.name===true&& (
          <>
            <Form.Control
              type="text"
              style={{ marginTop: '10px', marginBottom: '10px', width: '100%', borderRadius: '25px' }}
              placeholder={t("First Name...")}
              id="firstName"
              value={firstName}
              onChange={handleFirstNameChange}
              onKeyDown={e => e.stopPropagation()}
            />
            <Form.Control
              type="text"
              style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5%', width: '100%', borderRadius: '25px' }}
              placeholder={t("Last Name")}
              id="lastName"
              value={lastName}
              onChange={handleLastNameChange}
              onKeyDown={e => e.stopPropagation()}
            />
          </>
        )}

      </div>

      {stores.guestmode[0].other.email && (
        <div>
          <Form.Control
            type="email"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "5%",
              width: "90%",
              borderRadius: "25px",
            }}
            placeholder={t("Email Address")}
            id="email"
            value={email}
            onChange={handleEmailChange}
      
          />
       
        </div>
      )}
        {stores.guestmode[0].other.address && (
        <AddressemodeGuest 
          onSelectLocation={onSelectLocation}
          calculateDistanceFn={calculateDistanceFromInput}
        />)}


      {stores.guestmode[0].other.phone && (
        <div style={{ display: "flex"  }}>
          <PhoneInputNumber
            defaultCountry="FR"
            className="phone-input"
            placeholder={t("Enter Phone Number")}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          {/* <SendSMS onClick={handleSignup} style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '1%', width: '100%', borderRadius: '25px' }} >
            {t("send code")}
          </SendSMS> */}
        </div>
      )}
    
          {phonevalide === false && 
        <span
        className="error-message"
        style={{ 
          color: "red", 
          textAlign: "left", 
          fontFamily: "QuickSandLight, sans-serif", 
          marginLeft: "20px",
          fontSize: "14px",
          float: "left",
          paddingBottom: "5px"
        }}
      >
              <ErrorOutlineIcon style={{fontSize:"17px"}}/> 
          {t("Please enter a valid phone number with country code (e.g., +33612345678 for France)")}
        </span>
      }
{/*     
      {showDiv && (
        <div style={{ display: "flex" }}>
          <Form.Control
            type="number"
            style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5%', width: '70%', borderRadius: '25px' }}
            placeholder={t('Entre Code')}
            id="verificationCode"
            maxLength={8}
            value={verificationCode}
            onChange={handleCodeChange}
            onKeyDown={e => e.stopPropagation()}

          />
          <VerifButton onClick={() => {
            handleGuest();
            handleLogin();
          }
          } style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '1%', width: '40%', borderRadius: '25px' }} >
            {t("valide")}
          </VerifButton>
        </div>
      )} */}

{/* {
  stores.guestmode[0].other.phone === false && (
    <div>
 
      {errorMessage && (
        <div style={{ color: '#eb1700', marginBottom: '10px' }}>
          {errorMessage}
        </div>
      )}
  <SignupButton onClick={() => handleRegister()}>
        {t("Login")}
      </SignupButton>
    </div>  
  )
} */}
<SignupButton onClick={() => handleRegister()}>
        {t("Login")}
      </SignupButton>
      
      <ToastContainer
        position="top-center"
        autoClose={2000}
        pauseOnHover={false}

      />
    </StyledModalSignup>

  )
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      {...props}
    />
  )
}

const StyledModalSignup = styled(ReactModalAdapter).attrs({
  modalClassName: 'ModalSignup',
  overlayClassName: 'OverlaySignup'
})`
.ModalSignup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  min-height: 400px;
  width: 400px;
  text-align: center;
  z-index: 9999;
}

@media (max-width: 768px) {
  .ModalSignup {
    width: 90%;
    min-width: 90%;
  }
}
  .OverlaySignup {
      background: rgba(0, 0, 0, 0.2);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10000;
  }
`
const SendSMS = styled.button`
color:#fff;
background:#E67E22;
border:none;
border-radius: 25px;
width: 90%;
height: 40px;
font-size: 19px;
margin-bottom:20px;
margin-top:20px;

&:hover{
background: #fff;
border:1px solid #eb1700;;
color: #eb1700;;
}
`
const SignupButton = styled.button`
color:white;
background:#eb1700;
border:none;
border-radius: 25px;
width: 90%;
height: 40px;
font-size: 19px;
margin-bottom:20px;
margin-top:20px;

&:hover{
background: #fff;
border:1px solid #eb1700;;
color: #eb1700;;
}
`
const VerifButton = styled.button`
color:#fff;
background:#1D8348;
border:none;
border-radius: 25px;
width: 90%;
height: 40px;
font-size: 19px;
margin-bottom:20px;
margin-top:20px;

&:hover{
background: #fff;
border:1px solid #eb1700;;
color: #eb1700;;
}
`


const PhoneInputNumber = styled(PhoneInput)`
border-radius: 4px;
padding: 6px 12px;
font-size: 14px;
height: 10%;
margin-top: 10px;
margin-bottom: 10px;
margin-left: 15px;
width: 91%;
border-radius: 25px;
display: flex;
padding: 0.375rem 0.75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`