import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import SelectOption from "./selectOption/SelectOption.js";
import SubmitOrder from "./SubmitOrder.js";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import { BaseURI, ImageURI, store } from "../../../shared/index.js";
import { setScroll } from "../../../shared/slice/scroll/ScrollSlice.js";
import { useSelector } from "react-redux";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import {
  setEditOrder,
  setOpenModal,
} from "../../../shared/slice/restaurant/RestaurantSlice.js";
// import "./ProductModal.css"
function ProductModal() {
  const { t } = useTranslation();

  const editOrder = useSelector((state) => state.restaurant.editOrder);
  // const scroll = useSelector((state) => state.scroll.scroll);
  const currency = useSelector((state) => state.restaurant.menu?.currency);
  const openModal = useSelector((state) => state.restaurant.openModal);

  
  
  
  const [objectOptions, setObjectOptions] = useState([]);

 
  const [counter, setCounter] = useState({}); //counter
  // const [currency, setCurrency] = useState("");
  const [number, setNumber] = useState(0); // stay here
  const [checkedIndices, setCheckedIndices] = useState([]);
  const [checkedOption, setCheckedOption] = useState([]);
  const [product, setProduct] = useState({
    ...openModal.product,
    size: openModal.product?.size?.map((size) => ({
      ...size,
      optionGroups: [...openModal.product.optionGroups, ...size.optionGroups],
    })),
  });
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const orders = useSelector((state) => state.order.order);
  const open = openModal.open;
  useEffect(() => {
    if (openModal) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [openModal]);
  const [selectedSize, setSelectedSize] = useState(() => {
    // Early exit if no sizes are available
    if (!product?.size?.length) {
      return {
        size: "",
        optionGroupe: [],
        optionGroupeLength: 0,
        subOptionGroup: [],
        subOptionSelected: [],
        price: 0,
        tax: 0,
        taxId: "",
        note: "",
      };
    }
  
    // Directly determine the initial size
    const sizes = product.size;
    const initialSize =
      sizes.length === 1
        ? sizes[0]
        : sizes[0].name === "standard"
        ? sizes[1]
        : sizes[0];
  
    if (!initialSize) {
      return {
        size: "",
        optionGroupe: [],
        optionGroupeLength: 0,
        subOptionGroup: [],
        subOptionSelected: [],
        price: 0,
        tax: 0,
        taxId: "",
        note: "",
      };
    }
  
    // Cache frequently used values
    const { optionGroups = [], price = 0 } = initialSize;
    const tax = product.taxes?.[0]?.tax?.rate || 0;
    const taxId = product.taxes?.[0]?.tax?._id || "";
    const subOptionGroup = [];
  
    // Precompute subOptionGroup with minimal iterations
    for (const group of optionGroups) {
      for (const option of group.options || []) {
        if (option.subOptionGroup) {
          subOptionGroup.push(...option.subOptionGroup);
        }
      }
    }
  
    return {
      size: initialSize.name,
      optionGroupe: optionGroups,
      optionGroupeLength: optionGroups.length,
      subOptionGroup,
      subOptionSelected: [],
      price,
      priceHt: price / (1 + tax / 100),
      tax,
      taxId,
      note: "",
    };
  });
  useEffect(() => {
    // product?.size?.length === 1 && handleChange(product.size[0].name);
    // //

    // if (product?.size?.length > 1 && product?.size[0].name === "standard") {
    //   handleChange(product.size[1].name);
    // }
    // if (product?.size?.length > 1 && product?.size[0].name !== "standard") {
    //   handleChange(product.size[0].name);
    // }

    // product?.size?.length >1 && handleChange(product.size[0].name);

    if (editOrder && editOrder.open) {
      setCheckedIndices([...editOrder.checkedIndices]);
      setCheckedOption([...editOrder.checkedOptions]);
      //   //redux states are immutable
      const newObjectOptions = editOrder.objectOptions.map((obj) => ({
        ...obj,
      }));
      setObjectOptions(newObjectOptions);
      const counterObject = editOrder.counter[0];
      setCounter(counterObject);
      const findSize = product.size.find(
        (grpe) => grpe.name === editOrder.size
      );
      const sub = findSize.optionGroups.flatMap((groupe) => groupe.options);
      const suboption = sub.flatMap((option) => option.subOptionGroup);
      const tax = product.taxes[0]?.tax.rate;
      const taxId = product.taxes[0]?.tax._id;
      setSelectedSize({
        size: editOrder.size,
        optionGroupe: findSize.optionGroups,
        optionGroupeLength: findSize.optionGroups.length,
        subOptionGroup: suboption,
        subOptionSelected: [],
        price: findSize.price,
        priceHt: findSize.price / (1 + tax / 100),
        tax: tax,
        taxId: taxId,
        note: editOrder.note,
      });
    }
  }, []);

  const handleChange = (event) => {
    setSelectedSize({
      size: " ",
      optionGroupe: [],
      optionGroupeLength: 0,
      subOptionGroup: [],
      subOptionSelected: [],
      price: Number,
      tax: Number,
      taxId: "",
      note: "",
    });
    const rad = event?.target?.value || event;
    const findSize = product.size.find((grpe) => grpe.name === rad);
    console.log("wessfindSize",findSize)
    const sub = findSize.optionGroups.flatMap((groupe) => groupe.options);
    const suboption = sub.flatMap((option) => option.subOptionGroup);
    const tax = product.taxes[0].tax.rate ? product.taxes[0].tax.rate : 0;
    const taxId = product.taxes[0]?.tax?._id;

    setSelectedSize({
      size: rad,
      optionGroupe: findSize.optionGroups,
      optionGroupeLength: findSize.optionGroups.length,
      subOptionGroup: suboption,
      subOptionSelected: [],
      price: findSize.price,
      priceHt: findSize.price / (1 + tax / 100),
      tax: tax,
      taxId: taxId,
    });
    setObjectOptions([]);
    setCounter({});
    setCheckedOption([]);
    setCheckedIndices([]);
  };

  const groupName = selectedSize.optionGroupe;
  return (
    <>
      {selectedSize.optionGroupe && (
        <Modal
          isOpen={open}
          contentLabel="optionGroups"
          overlayClassName="OverlayOption"
          className="ModalSize"
          style={{ width: "50%", height: "80%" }}
        >
          <ButtonCloseContainer>
            <h4 style={{ paddingLeft: "18px", fontFamily: "QuicksandBold" }}>
              {product.name}
            </h4>
            <ButtonClose
              onClick={() => {
                store.dispatch(
                  setOpenModal({
                    product: undefined,
                    open: false,
                  })
                );
                store.dispatch(
                  setEditOrder({
                    open: false,
                    objectOptions: [],
                    checkedIndices: [],
                    checkedOptions: [],
                    counter: {},
                    orderQuantity: 0,
                    note: undefined,
                    size: "",
                  })
                );
                setSelectedSize({});
                store.dispatch(setScroll({ scroll: 0 }));
              }}
            >
              <ClearIcon style={{ fontSize: "27px" }} />
            </ButtonClose>
          </ButtonCloseContainer>
          <ContentContainer groupName={groupName}>
            <div className="product-popup">
              <div>
                <div>
                  <div>
                    <img
                      src={`${ImageURI}/combined-uploads/${product.image}`}
                      alt={product.image}
                      className="product-image"
                      style={{
                        width: window.innerWidth <= 768 ? "250px" : "150px",
                        height: window.innerWidth <= 768 ? "200px" : "150px",
                      }}
                    />
                    <div>
                      <p style={{ fontSize: "0.8rem" }}>
                        {product.description}
                      </p>
                    </div>
                  </div>

                  <div className="radio-container">
                    {product?.size?.length >= 2 &&
                      product?.size
                        .filter((grp) => grp.name !== "standard")
                        .map((grp, index) => (
                          <div
                            className="btn-group"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio2"
                              value={grp.name}
                              id={index}
                              autoComplete="off"
                              onChange={(event) => handleChange(event)}

                              // checked={selectedSize.size === grp.name || (product.size[0].name === "standard" && index === 1)}
                            />
                            <label
                              className={`btn btn-size ${
                                selectedSize.size === grp.name
                                  ? "btnSizeClicked"
                                  : ""
                              }`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                color: "#eb1700",
                              }}
                              htmlFor={index}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "22px" }}
                              >
                                {grp.name}{" "}
                              </span>
                              <small>
                                {parseFloat(
                                  grp.price +
                                    (apply === "product"
                                      ? frais !== null
                                        ? frais
                                        : grp.price * (taux / 100)
                                      : 0)
                                ).toFixed(2)}
                                {getSymbolFromCurrency(currency)}
                              </small>
                            </label>
                          </div>
                        ))}
                  </div>
                </div>

                {groupName ? (
                  <div>
                    <SelectOption
                      product={product}
                      selectedSize={selectedSize}
                      setNumber={setNumber}
                      setCounter={setCounter}
                      counter={counter}
                      setCheckedIndices={setCheckedIndices}
                      checkedIndices={checkedIndices}
                      setCheckedOption={setCheckedOption}
                      checkedOption={checkedOption}
                      objectOptions={objectOptions}
                    />

                    {selectedSize.size !== " " ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "7%",
                            marginBottom: "2%",
                          }}
                        >
                          <p style={{ fontFamily: "QuicksandBold" }}>
                            {t("Additional note")} :
                          </p>
                        </div>

                        <TextareaAutosize
                          id="noteTextarea"
                          aria-label=""
                          minRows={3}
                          minCols={20}
                          placeholder={t("leave your additional note")}
                          style={{
                            width: "90%",
                            border: "1px solid grey",
                            borderRadius: "5px",
                            textAlign: "left",
                            marginBottom: "80px",
                            paddingLeft: "5px",
                            fontFamily: "QuicksandRegular",
                          }}
                          value={selectedSize.note}
                          onChange={(e) =>
                            setSelectedSize((prevSize) => ({
                              ...prevSize,
                              note: e.target.value,
                            }))
                          }
                        />
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </ContentContainer>
          {selectedSize.size !== " " && (
            <div>
              <Footer>
                <SubmitOrder
                  selectedSize={selectedSize}
                  objectOptions={objectOptions}
                  counter={counter}
                  number={number}
                  checkedIndices={checkedIndices}
                  checkedOption={checkedOption}
                  product={product}
                  setSelectedSize={setSelectedSize}
                  setCheckedIndices={setCheckedIndices}
                  setCheckedOption={setCheckedOption}
                  setCounter={setCounter}
                  setObjectOptions={setObjectOptions}
                />
              </Footer>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
export default ProductModal;
const ButtonCloseContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  height: 27px;
  background-color: #fff;
  position: sticky;
  top: 0px;
  text-align: left;
  border-bottom: 1px solid #33333347;
  height: 35px;
  z-index: 1000;
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;

const Footer = styled.div`
  width: 100%;
  padding: 6px;
  border-top: 1px solid #33333347;
  background: #fff;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
  }
`;

const ContentContainer = styled.div`
  overflow-y: scroll;
  //  height: "80%";
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
