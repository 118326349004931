import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import {
  setEmail,
  setEmailExist,
  setModalLogin,
  setModalPrincipal,
  setModalSignup,
  setModelGuest,
} from "../../shared/slice/ModalLogin/ModalLoginSlice";
import { BaseURI, store } from "../../shared";
import { checkEmail } from "../../shared/slice/auth/AuthService";
import { eatorder } from "../../assets/images/exports";
import styled from "styled-components";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { useTranslation } from "react-i18next";
import {
  setLoggedInUser,
  setToken,
  setmodalCheckout,
} from "../../shared/slice/auth/AuthSlice";

export default function ModalPrincipal() {
  const { t } = useTranslation();
  const stores = useSelector((state) => state.restaurant.restaurantSelected);
  const principalModal = useSelector(
    (state) => state.modalLogin.modalPrincipal
  );
  const modeDelivery = useSelector((state) => state.modalMode.modeDelivery);
  const modeName = useSelector((state) => state.restaurant.modeName);
  // const email = useSelector((state) => state.modalLogin.email);

  const [addressEmail, setAddressEmail] = useState("");
  const [emailExists, setEmailExists] = useState(true);


  useEffect(() => {
    if (principalModal) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [principalModal]);

  const handleClose = () => {
    store.dispatch(setModalPrincipal({ modalPrincipal: false }));
    setAddressEmail("");
    // store.dispatch(setEmail({ email: "" }));
    store.dispatch(setScroll({ scroll: 0 }));
    setEmailExists(true);
  };

  const handleEmailChange = (e) => {
    setAddressEmail(e.target.value);
    store.dispatch(setEmail({ email: e.target.value }));
  };

  const checkEmailValid = async () => {
    if (addressEmail === "") {
      if (
        !modeDelivery &&
        stores?.guestmode[0].guestmode &&
        modeName !== "Delivery"
      ){    store.dispatch(setModalSignup({ modalSignup: true }));
      store.dispatch(setScroll({ scroll: 0 }));
      store.dispatch(setModalPrincipal({ modalPrincipal: false }));}
      else{
        setEmailExists(false)
      }
  
    } else {
      console.log("addressEmail",addressEmail);
      
      await checkEmail({ email: addressEmail })
        .then((res) => {
          store.dispatch(setEmail({ email: addressEmail }));
          if (res.exists === true) {
            store.dispatch(setEmailExist({ emailExist: true }));
            store.dispatch(setModalPrincipal({ modalPrincipal: false }));
            store.dispatch(setModalLogin({ modalLogin: true }));
            store.dispatch(setScroll({ scroll: 0 }));
          } else {
            if (
              !modeDelivery &&
              stores?.guestmode[0].guestmode &&
              modeName !== "Delivery"
            ) {
              console.log("4555");
              store.dispatch(setModalSignup({ modalSignup: true }));
              store.dispatch(setScroll({ scroll: 0 }));
              store.dispatch(setModalPrincipal({ modalPrincipal: false }));
            } else {
              setEmailExists(false);
            }

            //   store.dispatch(setEmailExist({ emailExist: false }));
            store.dispatch(setModalPrincipal({ modalPrincipal: true }));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const modelchekoutButtons = useSelector(
    (state) => state.authentification.modelchekoutButton
  );

  const OpenCheckOut = useSelector((state) => state.modalStores.OpenCheckOut);
  console.log("Checking for checkout...", modelchekoutButtons);
  const checkout = async () => {
    console.log("Checking for checkout...", modelchekoutButtons);
    if (OpenCheckOut) {
      store.dispatch(setmodalCheckout({ modalCheckout: true }));
    }
  };
  const googleAuth = async () => {
    try {
      const loginWindow = window.open(
        `${BaseURI}/client/google`,
        "Google Login",
        "width=600, height=400"
      );
  
      store.dispatch(setModalPrincipal({ modalPrincipal: false }));
  
      if (!loginWindow || loginWindow.closed || typeof loginWindow.closed === "undefined") {
        throw new Error("The pop-up window was blocked.");
      }
  
      const handleMessage = (event) => {
        if (event.origin !== window.location.origin) {
          console.warn("Ignoring message from unknown origin:", event.origin);
          return;
        }
  
        if (event.data.status === "loaded") {
          const url = new URL(event.data.url);
          const token = url.searchParams.get("token");
          const userJsonEncoded = url.searchParams.get("user");
          const userJsonDecoded = decodeURIComponent(userJsonEncoded);
          const userObject = JSON.parse(userJsonDecoded);
  
          store.dispatch(setToken({ token }));
          store.dispatch(setLoggedInUser({ user: userObject }));
          
          // Close the pop-up window after setting the token and user object
          loginWindow.close();
        }
      };
  
      window.addEventListener("message", handleMessage);
  
    } catch (error) {
      console.error("Verification Error:", error);
    }
  };
  

  const checkGust = () => {
    store.dispatch(setModalPrincipal({ modalPrincipal: false }));
    store.dispatch(setModelGuest({ ModelGuest: true }));
  };
  return (
    <>
      <StyledModalPrincipal
        isOpen={principalModal}
        onRequestClose={handleClose}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={eatorder}
            alt="EatOrder logo"
            style={{
              width: "150px",
              height: "50px",
              objectFit: "contain",
              margin: "20px",
            }}
          ></img>
        </div>

        {!emailExists && (
          <p
            style={{
              color: "red",
              padding: "10px",
              fontFamily: "QuickSandLight",
              fontSize: "14px",
              float: "center",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            {t("Email does not exist")}
          </p>
        )}

        <Form.Control
          style={{
            marginTop: "10px",
            marginBottom: "15px",
            marginLeft: "5%",
            width: "90%",
            borderRadius: "25px",
            textAlign: "center",
          }}
          type="email"
          placeholder={t("Email Address")}
          id="email"
          value={addressEmail}
          onChange={(e) => handleEmailChange(e)}
        />

        <LoginButton onClick={checkEmailValid}>{t("Continue")}</LoginButton>

        {
          <GoogleLoginButton onClick={googleAuth}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.75 24C19.3774 24 24.75 18.6274 24.75 12C24.75 5.37258 19.3774 0 12.75 0C6.12258 0 0.75 5.37258 0.75 12C0.75 18.6274 6.12258 24 12.75 24Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.95 12.1708C19.95 11.639 19.9023 11.1276 19.8136 10.6367H12.75V13.5379H16.7864C16.6125 14.4754 16.0841 15.2697 15.2898 15.8015V17.6833H17.7136C19.1318 16.3776 19.95 14.4549 19.95 12.1708Z"
                fill="#4285F4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7501 19.4998C14.7751 19.4998 16.4729 18.8282 17.7138 17.6828L15.2899 15.8009C14.6183 16.2509 13.7592 16.5168 12.7501 16.5168C10.7967 16.5168 9.1433 15.1975 8.55353 13.4248H6.04785V15.368C7.28194 17.8191 9.8183 19.4998 12.7501 19.4998Z"
                fill="#34A853"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.55341 13.425C8.40341 12.975 8.31818 12.4943 8.31818 12C8.31818 11.5057 8.40341 11.025 8.55341 10.575V8.63184H6.04773C5.53977 9.64434 5.25 10.7898 5.25 12C5.25 13.2102 5.53977 14.3557 6.04773 15.3682L8.55341 13.425Z"
                fill="#FBBC05"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7501 7.48295C13.8513 7.48295 14.8399 7.86136 15.6172 8.60455L17.7684 6.45341C16.4695 5.24318 14.7717 4.5 12.7501 4.5C9.8183 4.5 7.28194 6.18068 6.04785 8.63182L8.55353 10.575C9.1433 8.80227 10.7967 7.48295 12.7501 7.48295Z"
                fill="#EA4335"
              />
            </svg>
            <span> {t("Continue with Google")}</span>
          </GoogleLoginButton>
        }

        {!modeDelivery &&
          stores?.guestmode[0].guestmode &&
          modeName !== "Delivery" && (
            <LoginButtons onClick={checkGust}>{t("Guest mode")}</LoginButtons>
          )}
      </StyledModalPrincipal>
    </>
  );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}

const StyledModalPrincipal = styled(ReactModalAdapter).attrs({
  modalClassName: "Modal",
  overlayClassName: "Overlay",
})`
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    height: 440px;
    width: 400px;
    text-align: center;
  }
  @media (max-width: 768px) {
    .Modal {
      width: 90%;
      min-width: 90%;
    }
  }

  .Overlay {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;
const SignUpButtons = styled.button`
  color: #fff;
  background: #aaaaaa;
  border: none;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  font-size: 19px;
  margin-top: 12px;
  //   &:hover {
  //     background: #fff;
  //     border: 1px solid var(--primaryColor);
  //     color: var(--primaryColor);
  //   }
`;
const LoginButtons = styled.button`
  color: #fff;
  background: #aaaaaa;
  border: none;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  font-size: 19px;

  //   &:hover {
  //     background: #fff;
  //     border: 1px solid var(--primaryColor);
  //     color: var(--primaryColor);
  //   }
`;
const LoginButton = styled.button`
  color: #fff;
  background: #eb1700;
  border: none;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  font-size: 19px;
  //   margin-bottom:10px;

  &:hover {
    background: #fff;
    border: 1px solid #eb1700;
    color: red;
  }
`;
const GoogleLoginButton = styled.button`
  display: flex;
  padding: 2%;
  width: 90%;
  margin: 15px;
  background: #4285f4;
  color: white;
  border-radius: 25px;
  font-size: 17px;
  margin-left: 5%;
  justify-content: center;
  height: 40px;

  &:hover {
    background: #fff;
    border: 1px solid #4285f4;
    color: #4285f4;
  }
`;
